import React, { Component } from 'react';
import '../AnalyzeResult.scss';

interface ResumeCardItemDetailProps {
    title: string,
    value: string,
}

interface state {
    
}

class ResumeCardItemDetail extends Component<ResumeCardItemDetailProps, state> {
    public render() {
        const {title, value} = this.props
        return (
            <div className="analyze_result__content-item">
              <div className="analyze_result__content-item-name">{title}: </div>
              <div>{value} </div>
            </div>
        )
    }
}

export default ResumeCardItemDetail