import React, { Component } from 'react';
import '../AnalyzeResult.scss';

interface props {
  title: string,
  icon: string,
}

interface state {
    
}

class ResumeCardTitle extends Component<props, state> {
    public render() {
      const {title, icon} = this.props
        return (
            <div className="analyze_result__hr">
            <div className="analyze_result__hr-info">
              <img src={icon} alt="解析结果图标" />
              <span>{title}</span>
            </div>
            <div className="analyze_result__hr-line"></div>
          </div>
        )
    }
}

export default ResumeCardTitle