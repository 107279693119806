
import Icon from '@ant-design/icons';
import React from 'react';

interface UcPinIconProps {
    imgSrc: string,
    imgStyle?: object
}

class UcPinIcon extends React.Component<UcPinIconProps> {

    public render() {
        const { imgSrc, imgStyle } = this.props
        return (
            <Icon
                component={() => (
                    <img
                        style={{ width: '1em', height: '1em', ...imgStyle }}
                        src={imgSrc}
                        alt="icon"
                    />
                )}
            />

        )
    }

}

export default UcPinIcon
