import React from 'react';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './App.scss';
import Router from './router/index';
import UcPinHeader from './components/UcPinHeader/UcPinHeader';
import UcPinFooter from './components/UcPinFooter/UcPinFooter';

function App() {
  return (
    <div className="App">
      <UcPinHeader />
        <Router />
      <UcPinFooter />
    </div>
  );
}

export default App;
