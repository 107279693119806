import React, { Component } from 'react';
import './UcPinLoading.scss';
import { LOADINGICON } from '../../utils/staticEnum'
interface UcPinLoadingProps {
    isLoading: boolean
}

interface UcPinLoadingState { }

class UcPinLoading extends Component<UcPinLoadingProps, UcPinLoadingState> {
    public state = {
    }

    public render() {
        const { isLoading } = this.props
        return (
            <div>
                {isLoading &&
                    <div className="ucpin_loading">
                        <img src={LOADINGICON} alt="等待加载图标" />
                        <p>正在加载中...</p>

                    </div>}
            </div>
        );
    }
}

export default UcPinLoading;