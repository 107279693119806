import React from 'react';
import './AIAnalyze.scss';
import { Upload, message, Button, Input, } from 'antd';
import UploadService from '../../service/uploadService/index';
import { Link, RouteComponentProps } from 'react-router-dom'
import UcPinLoading from '../../components/UcPinLoading/UcPinLoading';
import UcPinIcon from '../../components/UcPinIcon/UcPinIcon';
import {imgUrl} from '../../utils/staticEnum'

interface AIResolveProps { }

interface AIResolveState {
  showContent1: boolean
  showContent2: boolean
  showContent3: boolean
  devImg: string,
  txt: string,
  fileList: any,
  key: string,
  loading: boolean,
  url: string,
  count: number,
}

class AIAnalyze extends React.Component<RouteComponentProps, AIResolveState> {
  public state = {
    showContent1: true,
    showContent2: false,
    showContent3: false,
    devImg: "https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/developing.png",
    txt: '',
    fileList: [],
    key: '',
    loading: false,
    url: '#',
    count: 0,
  }

  componentDidMount() {
  }

  hanldeNavChange = (s: any) => {
    console.log(s)
    if (s === 1) {
      this.setState({
        showContent1: true,
        showContent2: false,
        showContent3: false,
      })
    } else if (s === 2) {
      this.setState({
        showContent1: false,
        showContent2: true,
        showContent3: false,
      })
    } else {
      this.setState({
        showContent1: false,
        showContent2: false,
        showContent3: true,
      })
    }
  }

  // 简历文件上传
  handleFileChange = (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ fileList })

    const { status } = info.file;
    if (status === 'done') {
      if (info.fileList[0].response.code === 1001) {
        message.error(`${info.file.name} ${info.fileList[0].response.msg} ! `);
        return;
      }
      message.success(`${info.file.name} 简历上传成功 ! `);
      this.setState({
        key: info.fileList[0].response.data.key,
        url: '/analyze_result'
      })
    } else if (status === 'error') {
      message.error(`${info.file.name} 简历上传失败。`);
    }
  }

  handleInput = async (e: any) => {
    this.setState({
      txt: e.target.value
    })
  }

  countInput = (e: any) => {
    this.setState({
      count: e.target.value.length
    })
  }

  submitTxt = async () => {
    if (!this.state.txt) {
      message.error("简历内容不能为空");
      return;
    }
    this.setState({ loading: true });
    let upload = new UploadService();
    let data = {
      type: 'txt',
      txt: this.state.txt,
    }
    const res: any = await upload.uploadTxt(data);
    if (res.code === 200) {
      this.setState({
        key: res.data.key,
      }, () => {
        if (this.state.key) {
          this.props.history.push({
            pathname: '/analyze_result',
            state: { key: this.state.key }
          })
        }
      })
    } else {
      message.error('简历上传失败 !')
    }
    this.setState({
      loading: false,
    })
  }

  public render() {
    const { Dragger } = Upload;
    const { count } = this.state;
    const props = {
      name: 'file',
      data: {
        type: 'file',
      },
      onChange: this.handleFileChange,
      // 线上
      // action: 'https://api.ucpin.com/v1/ai/parse',
      // 本地
      action: process.env.NODE_ENV === 'production'?'https://api.ucpin.com/v1/ai/parse':'/ai/parse'
    };

    return (
      <div>
        <UcPinLoading isLoading={this.state.loading} />
        <div className="analyze_body">
          <svg className="hidden">
            <defs>
              <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z" />
            </defs>
          </svg>
          <div className="analyze_container">
            <section>
              <div className="analyze_tabs analyze_tabs-style-shape">
                <nav>
                  <ul>
                    <li className={this.state.showContent1 ? "tab-current" : ''}>
                      <Link to="/analyze/analyze_upload01" onClick={() => this.hanldeNavChange(1)}>
                        <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                          <use xlinkHref="#tabshape"></use>
                        </svg>
                        <span>文件解析</span>
                      </Link>
                    </li>
                    <li className={this.state.showContent2 ? "tab-current" : ''}>
                      <Link to="/analyze/analyze_upload02" onClick={() => this.hanldeNavChange(2)}>
                        <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                          <use xlinkHref="#tabshape"></use>
                        </svg>
                        <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                          <use xlinkHref="#tabshape"></use>
                        </svg>
                        <span>文本解析</span>
                      </Link>
                    </li>
                    <li className={this.state.showContent3 ? "tab-current" : ''}>
                      <Link to="/analyze/analyze_upload03" onClick={() => this.hanldeNavChange(3)}>
                        <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                          <use xlinkHref="#tabshape"></use>
                        </svg>
                        <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                          <use xlinkHref="#tabshape"></use>
                        </svg>
                        <span>列表解析</span>
                      </Link>
                    </li>
                  </ul>
                </nav>

                <div className="analyze_content-wrap"
                  style={{ display: this.state.showContent1 ? 'block' : 'none' }}>
                  <Dragger {...props} fileList={this.state.fileList}>
                    <p className="ant-upload-drag-icon">
                      <UcPinIcon imgSrc={imgUrl.Analyze.upload} imgStyle={{width: '7rem', height: '5.8rem'}}/>
                    </p>
                    <p className="ant-upload-text">点击或者拖动文件至框内</p>
                  </Dragger>
                  <Button
                    disabled={this.state.key === '' ? true : false}
                    className="analyze_content-btn"
                    type="primary"
                  >
                    <Link
                      to={{
                        pathname: "/analyze_result",
                        state: { key: this.state.key }
                      }}>开始解析</Link></Button>
                </div>
                <div className="analyze_content-wrap"
                  style={{ display: this.state.showContent2 ? 'block' : 'none' }}>
                  <Input.TextArea
                    className="analyze_content-input"
                    placeholder="支持中文文本, 暂不支持图片, 最多5000字"
                    rows={12}
                    maxLength={5000}
                    onChange={(e) => this.countInput(e)}
                    onBlur={(e) => this.handleInput(e)} />
                  <span className="analyze_content-count">{count}/5000</span>
                  <Button
                    onClick={this.submitTxt}
                    className="analyze_content-btn2" type="primary">
                    开始解析
                    </Button>
                </div>

                <div className="analyze_content-wrap"
                  style={{ display: this.state.showContent3 ? 'block' : 'none' }}>
                  <img className="analyze_content-wrap-img" src={this.state.devImg} alt="开发中图片" />
                  <p>施工中, 敬请期待~</p>
                </div>
              </div>
            </section>

          </div>
        </div >
      </div>
    );
  }
}

export default AIAnalyze;