import React from 'react';
import './UcPinHeader.scss';
import { Link } from "react-router-dom";
import { Menu } from 'antd';

interface HeaderListProps {
  id: string | undefined,
  name: string,
  link?: string,
}
interface UcPinHeaderProps { }

interface UcPinHeaderState {
  logo: string,
  headerList: HeaderListProps[],
  loginList: HeaderListProps[],
  aiList: HeaderListProps[],
  show: string,
}

class UcPinHeader extends React.Component<UcPinHeaderProps, UcPinHeaderState> {
  public state = {
    show: 'none',
    logo: 'https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/logo.png',
    // 头部导航左侧信息
    headerList: [
      { id: '0', name: '首页', link: 'https://www.ucpin.com' },
      { id: '1', name: 'AI产品', },
      { id: '2', name: 'ucdata', link: 'https://ucdata.ucpin.com' },
      { id: '3', name: '找工作', link: 'https://ucdata.ucpin.com/search' },
      { id: '4', name: '招聘会', link: 'https://ucdata.ucpin.com/jobfair' },
    ],
    // 头部导航右侧信息
    loginList: [
      { id: '0', name: '猎头登录', link: 'https://www.ucpin.com/login' },
      { id: '1', name: 'HR登录', link: 'https://www.ucpin.com/login' },
      { id: '2', name: '求职者登录', link: 'https://www.ucpin.com/login' },
    ],
    // AI产品链接
    aiList: [
      { id: '0', name: '简历解析', link: '/ai' },
      // { id: '1', name: '首页', link: '#' },
      // { id: '2', name: '首页', link: '#' },
    ],
  }

  componentDidMount() {
    // document.addEventListener('mouseout', this.hiddenoption);
  }

  // 其他区域隐藏下拉菜单
  hiddenoption = () => {
    this.setState({
      show: 'none',
    })
  }
  // 显示菜单
  selectOption = (e: any) => {
    this.setState({
      show: 'block',
    })
    e.nativeEvent.stopImmediatePropagation();
  }

  public render() {
    const state = this.state;
    return (
      <header className="ucpin_header">
        <div><img src={state.logo} alt="logo" className="ucpin_header__logo" /></div>
        <div>
          <ul className="ucpin_header__nav">
            {state.headerList.map(headerListItem =>

              <li className="ucpin_header__nav-item"
                id={headerListItem.id}
                key={'headerListItem' + headerListItem.id}
              >
                {headerListItem.id === '1'
                  ? <div
                    className={this.state.show === 'block' ? 'ucpin_ai-nav-active' : 'ucpin_ai-nav'}
                    onMouseOver={this.selectOption}
                    onMouseOut={this.hiddenoption}>
                    <Link to='/'>
                      {headerListItem.name}
                    </Link>

                    <Menu
                      className="ucpin_select"
                      style={{ display: this.state.show }}>
                      {state.aiList.map(aIListItem =>
                        <Menu.Item key={aIListItem.id}>
                          <Link to={aIListItem.link}>
                            {aIListItem.name}
                          </Link>
                        </Menu.Item>
                      )}
                    </Menu>
                  </div>
                  : <a href={headerListItem.link}>{headerListItem.name}</a>
                }
              </li>
            )}
          </ul>
        </div>
        <div className="login_nav">
          <ul className="ucpin_header__nav">
            {state.loginList.map(item =>
              <li
                className="ucpin_header__nav-item login"
                id={item.id}
                key={'login' + item.id}>
                <a href={item.link}>
                  {item.name}
                </a>
              </li>
            )}
          </ul>
        </div>
      </header>
    );
  }
}

export default UcPinHeader;
