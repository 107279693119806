import Http from '../Http';


class UploadService {

    async uploadTxt (data: object) {
        let url = '/ai/parse';
        const res = await Http.post(url, data);
        return res;
    }

    async getResumeInfo (key: object) {
        let url = '/ai/parse/result';
        const res = await Http.post(url, key);
        return res;
    }

}

export default UploadService;
