import React from 'react';
import './UcPinFooter.scss'

interface FooterProps {
  img1: string,
  txt1: string,
  img2: string,
  txt2: string,
  img3: string,
  txt3: string,
  record: string,
}
interface UcPinFooterProps {
}
interface UcPinFooterState { 
  footerData: FooterProps
}

class UcPinFooter extends React.Component<UcPinFooterProps, UcPinFooterState> {
  public state = {
    // 底部信息
    footerData: {
      img1: "https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/icon1.png",
      txt1: '快速发布职位',
      img2: "https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/icon1.png",
      txt2: '求职找工作',
      img3: "https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/icon2.png",
      txt3: 'service@ucpin.com',
      record: 'Copyright© 2017-2020 UCPIN.COM All Rights Reserved. 粤ICP备13016216号-3',
    }
  }
  public render() {
    const state = this.state;
    return (
      <div className="ucpin_web_footer">
        <div className="ucpin_web_footer--concact">
          <img src={state.footerData.img1} alt="" />
          <div className="ucpin_web_footer--concact-t">{state.footerData.txt1}</div>
        </div>
        <div className="ucpin_web_footer--concact">
          <img src={state.footerData.img2} alt="" />
          <div className="ucpin_web_footer--concact-t">{state.footerData.txt2}</div>
        </div>
        <div className="ucpin_web_footer--concact">
          <img src={state.footerData.img3} alt="" />
          <div className="ucpin_web_footer--concact-t">{state.footerData.txt3}</div>
        </div>
        <div className="ucpin_web_footer--record">{state.footerData.record}</div>
      </div>
    );
  }
}

export default UcPinFooter;
