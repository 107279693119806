import React, { Component } from 'react';
import '../AnalyzeResult.scss';
import ResumeCardItemDetail from './ResumeCardItemDetail'

export interface ResumeCardItemProps {
    info: {
        basic_info: any,
        expect: any,
        contact:any,
    }
}

interface state {

}

class ResumeCardBasic extends Component<ResumeCardItemProps, state> {
    public render() {
        const { basic_info, expect, contact } = this.props.info
        return (
            <div className="analyze_result__content">
                <ResumeCardItemDetail title="姓名" value={basic_info.name} />
                <ResumeCardItemDetail title="工作年限" value={basic_info.gender}  />
                <ResumeCardItemDetail title="ID" value={basic_info.id_number}  />
                <ResumeCardItemDetail title="现居住地" value={basic_info.location_province+basic_info.location_city}  />
                <ResumeCardItemDetail title="性别" value={basic_info.gender}  />
                <ResumeCardItemDetail title="婚姻情况" value={basic_info.marital_status}  />
                <ResumeCardItemDetail title="年龄" value={basic_info.birthday}  />
                <ResumeCardItemDetail title="是否已育" value={basic_info.marital_status}  />
                <ResumeCardItemDetail title="籍贯" value={basic_info.residence}  />
                <ResumeCardItemDetail title="求职状态" value={basic_info.status}  />
                <ResumeCardItemDetail title="手机" value={contact.mobile}  />
                <ResumeCardItemDetail title="期望行业" value={expect.industries}  />
                <ResumeCardItemDetail title="QQ" value={contact.qq}  />
                <ResumeCardItemDetail title="期望职位" value={expect.title}  />
                <ResumeCardItemDetail title="邮箱" value={contact.email}  />
                <ResumeCardItemDetail title="期望地点" value={expect.city}  />
                <ResumeCardItemDetail title="微信" value={contact.wechat}  />
                <ResumeCardItemDetail title="期望月薪" value={expect.salary}  />
            </div>
        )
    }
}

export default ResumeCardBasic