import Http from '../Http';

class ApplyService {

    async getVerifyCode (mobile: number) {
        let url = '/send/verify/code';
        let data = { mobile };
        const res = await Http.post(url, data);
        console.log(res)
        return res;
    }
    async createApply(data: object) {
        let url = '/ai/apply/create';
        const res = await Http.post(url, data)
        return res;
    }

}

export default ApplyService;
