import React, { Component } from 'react';
import './Banner.scss';
import { Link } from "react-router-dom";
interface BannerProps {
  handleModel(): void,
}

interface BannerState { }

class Banner extends Component<BannerProps, BannerState> {
  public state = {
  }

  public render() {
    return (
      <div className="body">
        <div className="body__content">
          <div className="body__content-title">AI简历解析</div>
          <div className="body__content-des">基于自主研发的自然语言理解框架及布局分析引擎, 快速高效的处理海量简历</div>
          <div className="body__content-btn">
            <Link to='/analyze'>
              <button className="body__content-btn-left">
                体验解析
            </button>
            </Link>
            <button className="body__content-btn-right"
              onClick={this.props.handleModel}>申请试用</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;