const AISTATICURL = 'https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/'

const LOADINGICON = AISTATICURL + 'loading70.gif'
const imgUrl = {
    AnalyzeResult : {
        user: AISTATICURL+'info.png',
        work: AISTATICURL+'work.png',
        project: AISTATICURL+'proInfo.png',
        education: AISTATICURL+'education.png',
        other: AISTATICURL+'other.png',
        back: AISTATICURL+'back.png',
    },
    Analyze : {
        upload: AISTATICURL+'upload.png'
    }
}

export {
    imgUrl,
    LOADINGICON
}