import React, { Component } from 'react';
import '../AnalyzeResult.scss';
import ResumeCardItemDetail from './ResumeCardItemDetail'
import ResumeCardItemP from './ResumeCardItemP'

interface props {
    info: {
        occupations: any[],
    }
}

interface state {

}

class ResumeCardWork extends Component<props, state> {
    public render() {
        const { occupations } = this.props.info
        return (
            <div>
                {occupations.map((occupationsItem: any, occupationsIndex) =>
                    <div key={occupationsIndex}>
                        <h3>{occupationsItem.start_time + '-' + occupationsItem.end_time + ' ' + occupationsItem.company + ' '}</h3>
                        <div className="analyze_result__content">
                            <ResumeCardItemDetail title="职位" value={occupationsItem.title} />
                            <ResumeCardItemDetail title="薪资" value={occupationsItem.salary} />
                            <ResumeCardItemDetail title="部门" value={occupationsItem.department} />
                            <ResumeCardItemDetail title="证明人" value={occupationsItem.referer} />
                            <ResumeCardItemDetail title="行业" value={occupationsItem.industry} />
                            <ResumeCardItemDetail title="证明人电话" value={occupationsItem.referer_mobile} />
                            <ResumeCardItemP title="工作描述" value={occupationsItem.desc} type='normal'/>
                            <ResumeCardItemP title="离职原因" value={occupationsItem.leave_reason} type='normal' />
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default ResumeCardWork