import React, { Component } from 'react';
import '../AnalyzeResult.scss';

interface ResumeCardItemProps {
    title: string,
    value?: string,
    arr?: any[],
    type: string,
}

interface state {

}

class ResumeCardItemP extends Component<ResumeCardItemProps, state> {
    public render() {
        const { title, value, type, arr } = this.props
        return (
            <div className="analyze_result__content-row">
                <div className="analyze_result__content-row-name">{title}: </div>
                {type==='map'?arr&&arr.map((skillsItem: any, skillsIndex: any) => 
                    <span key={skillsIndex}>{skillsItem.name + ' '}</span>
                )
                    : <div>{value}</div>
                }
            </div>
        )
    }
}

export default ResumeCardItemP