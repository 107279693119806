import React, { Component } from 'react';
import './AnalyzeResult.scss';
import UploadService from '../../../service/uploadService/index';
import { message } from 'antd';
import ResumeCardHeader from './components/ResumeCardHeader'
import ResumeCardTitle from './components/ResumeCardTitle'
import ResumeCardBasic from './components/ResumeCardBasic'
import ResumeCardWork from './components/ResumeCardWork'
import ResumeCardExp from './components/ResumeCardExp'
import ResumeCardItemP from './components/ResumeCardItemP'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { imgUrl } from '../../../utils/staticEnum'
import UcPinLoading from '../../../components/UcPinLoading/UcPinLoading'
import creatHistory from 'history/createBrowserHistory'

interface AnalyzeResultProps {
  location: {
    state: {
      key: string,
    },
  },
  withRouter: RouteComponentProps,
}

interface AnalyzeResultState {
  info: {
    basic_info: any,
    expect: any,
    contact: any,
    occupations: any[],
    projects: any[],
    educations: any[],
    skills: any[],
  },
  isNav: boolean,
  infoJson: string,
  loading: boolean,
}

class AnalyzeResult extends Component<AnalyzeResultProps, AnalyzeResultState> {
  public state = {
    info: {
      basic_info: {
        self_evaluate: '',
      },
      expect: {},
      contact: {},
      occupations: [],
      projects: [],
      educations: [],
      skills: []
    },
    isNav: true,
    infoJson: '',
    loading: false,
  }

  async componentDidMount() {
    let key = this.props.location.state.key;
    if (!key) {
      message.error("简历上传有误 ! ")
      return;
    } else {
      this.setState({ loading: true })
      let data = { key };
      let uploadServe = new UploadService();
      const res: any = await uploadServe.getResumeInfo(data);
      if (res.code === 200) {
        let infoJson = JSON.stringify(res.data, null, 2);
        this.setState({
          info: res.data,
          infoJson,
        })
      } else {
        message.error("简历上传有误 ! ")
      }
      this.setState({
        loading:false,
      })
    }
  }

  gotoBack = () => {
    const history = creatHistory();
    history.goBack();
  }

  changeNav = (type: string) => {
    type === 'result' ? this.setState({ isNav: true }) : this.setState({ isNav: false })
  }

  public render() {
    return (
      <div className="analyze_result_body">
        <UcPinLoading isLoading={this.state.loading} />
        <div className="analyze_result_margin"></div>
        <div className="analyze_result">
          <div className="analyze_result__btns">
            <div className="analyze_result__nav">
              <button className={this.state.isNav ? 'analyze_result__nav-active' : 'analyze_result__nav-no'} onClick={() => this.changeNav('result')}>解析结果</button>
              <button className={this.state.isNav ? 'analyze_result__nav-no' : 'analyze_result__nav-active'} onClick={() => this.changeNav('data')}>返回数据</button>
            </div>
            <div onClick={this.gotoBack}>
              <img src={imgUrl.AnalyzeResult.back} alt="返回图标" />
              <button>
                返回演示页
              </button>
            </div>
          </div>

          {this.state.isNav ? <div>
            <ResumeCardHeader info={this.state.info} />
            <ResumeCardTitle title="基本信息" icon={imgUrl.AnalyzeResult.user} />
            <ResumeCardBasic info={this.state.info} />
            <ResumeCardTitle title="工作经历" icon={imgUrl.AnalyzeResult.work} />
            <ResumeCardWork info={this.state.info} />
            <ResumeCardTitle title="项目经历" icon={imgUrl.AnalyzeResult.project} />
            <ResumeCardExp info={this.state.info.projects} type="projects" />
            <ResumeCardTitle title="教育经历" icon={imgUrl.AnalyzeResult.education} />
            <ResumeCardExp info={this.state.info.educations} type="educations" />
            <ResumeCardTitle title="其他信息" icon={imgUrl.AnalyzeResult.other} />
            <ResumeCardItemP title="专业技能" arr={this.state.info.skills} type='map' />
            <ResumeCardItemP title="专业证书" arr={this.state.info.skills} type='map' />
            <ResumeCardItemP title="自我介绍" value={this.state.info.basic_info.self_evaluate} type='normal' />
          </div>
            : <pre style={{ 'height': '700px' }}>
              {this.state.infoJson}
            </pre>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(AnalyzeResult as any);