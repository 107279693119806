import React from 'react';
import './Main.scss';
import Banner from './components/Banner/Banner';
import MainCard from './components/MainCard/MainCard';
import ApplyModel from './components/ApplyModel/ApplyModel';

interface Props {}

interface MainCardState {
  mainCardImg1: string,
  mainCardTitle1: string,
  mainCardDes1: string,
  mainCardImg2: string,
  mainCardTitle2: string,
  mainCardDes2: string,
  mainCardImg3: string,
  mainCardTitle3: string,
  mainCardDes3: string,
}

interface MainState {
  mainData: MainCardState,
  openModel: boolean,
}

class Main extends React.Component<Props, MainState> {

  handleModel = () => { 
    this.setState({
      openModel: true
    })
  }
  closeModel = () => { 
    this.setState({
      openModel: false
    })
  }
  public state = {
    openModel: false,
    // 产品介绍
    mainData: {
      mainCardImg1: 'https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/des3.png',
      mainCardTitle1: '智能简历解析引擎',
      mainCardDes1: '采用先进的自然语言处理的深度学习技术,充分利用海量标注数据,对简历涉及的150余种特征做处理,提供高质量的识别结果;',
      mainCardImg2: 'https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/des2.png',
      mainCardTitle2: '应用场景广泛',
      mainCardDes2: '针对众多具体的OCR应用场景,进行了大量的模型优化,在职位推荐, 人才匹配, 人才搜索等场景具有很好的应用效果。',
      mainCardImg3: 'https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/des1.png',
      mainCardTitle3: '简单易用',
      mainCardDes3: '直接通过HTTP调用API接口, 对关键的字段已做规范化处理, 提供多种示例代码供开发者使用, 支持多种终端设备及环境, 复用使用简单快捷, 兼容性强',
    },

  }

  componentDidMount() {

  }

  public render() {
    const s = this.state;
    const main = this.state.mainData;
    return (
      <div>
        <Banner 
          handleModel={this.handleModel}
        />
        <MainCard
          mainCardImg={main.mainCardImg1}
          mainCardTitle={main.mainCardTitle1}
          mainCardDes={main.mainCardDes1}
          imgLeft />
        <MainCard
          mainCardImg={main.mainCardImg2}
          mainCardTitle={main.mainCardTitle2}
          mainCardDes={main.mainCardDes2}
        />
        <MainCard
          mainCardImg={main.mainCardImg3}
          mainCardTitle={main.mainCardTitle3}
          mainCardDes={main.mainCardDes3}
          imgLeft />
        <ApplyModel
          handleModel={this.handleModel}
          closeModel={this.closeModel}
          openModel={s.openModel}
        />
      </div>
    );
  }
}

export default Main;