import React, { Component } from 'react';
import '../AnalyzeResult.scss';

interface props {
  info: any
}

interface state {
    
}

class ResumeCardHeader extends Component<props, state> {
    public render() {
      const {basic_info, expect} = this.props.info
        return (
            <div className="analyze_result__top">
            <div className="analyze_result__top-title">{basic_info.name}</div>
            <div className="analyze_result__top-des">
              <span>{expect.city}</span>
              {expect.city && <div className="analyze_result__top-des-d">|</div>}
              <span>{basic_info.work_experience}</span>
              {basic_info.work_experience && <div className="analyze_result__top-des-d">|</div>}
              <span>{basic_info.birthday}</span>
              {basic_info.birthday && <div className="analyze_result__top-des-d">|</div>}
              <span>{basic_info.status}</span>
            </div>
          </div>
        )
    }
}

export default ResumeCardHeader