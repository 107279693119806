import React from 'react';
import './MainCard.scss';

interface MainCardProps {
    mainCardImg: string,
    mainCardTitle: string,
    mainCardDes: string,
    imgLeft?: boolean,
}
interface MainCardState { }

class MainCard extends React.Component<MainCardProps, MainCardState> {
    public state = {}
    public render() {
        const props = this.props;
        return (
            <div>
                {props.imgLeft
                    ? <div className="gray">
                        <div className="card">
                            <div className="card__img">
                                <img src={props.mainCardImg} alt="技术介绍图片" />
                            </div>
                            <div className="card__word">
                                <div className="card__word--title">{props.mainCardTitle}</div>
                                <div className="card__word--des">{props.mainCardDes}</div>
                            </div>
                        </div>
                    </div>
                    : <div className="card">
                        <div className="card__word">
                            <div className="card__word--title">{props.mainCardTitle}</div>
                            <div className="card__word--des">{props.mainCardDes}</div>
                        </div>
                        <div className="card__img">
                            <img src={props.mainCardImg} alt="技术介绍图片" />
                        </div>
                    </div>}
            </div>
        );
    }
}

export default MainCard;
