import React, { } from 'react';
import './ApplyModel.scss';
import { Form, Input, Button, message } from 'antd';
import {
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    HomeOutlined,
    CloseOutlined,
    KeyOutlined
} from '@ant-design/icons';
import ApplyService from '../../../../service/applyService/index'


interface ApplyModelProps {
    handleModel(): void,
    closeModel(): void,
    openModel: boolean
}
interface ApplyModelState {
    // name: string,
}

class ApplyModel extends React.Component<ApplyModelProps, ApplyModelState> {
    public state = {
        userName: '',
        mobile: 0,
        eMail: '',
        company: '',
        advice: '',
        isTimeOut:false,
        getCodeText: '获取验证码',
        count: 60
    }
    getInputVal = (name: string, e: any) => {
        const val = e.target.value;
        if (val) {
            this.setState({
                [name]: val
            })
        }
    }

    getMobile = (e:any) => {
        const val = e.target.value;
        if (val) {
            this.setState({
                mobile: val
            })
        }
    }

     getCode = async() => {
        const mobile = this.state.mobile;
        if (!mobile) {
            message.error('请输入手机号码 ! ');
            return;
        }
        let apply = new ApplyService();
        const res: any = await apply.getVerifyCode(mobile);
        if(res.code === 200) {
            message.success('验证码已发送, 请注意查收');
            this.setTime()
        }else {
            message.error('验证码发送失败, 请稍后再试');
        }

    }

    // 获取验证码倒计时
    setTime () {
      let count = this.state.count;
      console.log(count)
      const timer = setInterval(() => {
        this.setState({ 
            count: (count--),
            isTimeOut: true,
            getCodeText: count+'s后重新发送'
        }, () => {
            console.log(this.state.count)
          if (count === 0) {
            clearInterval(timer);
            this.setState({
              isTimeOut: false ,
              getCodeText: '获取验证码',
              count: 60
            })
          }
        });
      }, 1000);
    }


    public render() {
        const { openModel } = this.props;
        const str = "^((13[0-9])|(14[1]|[4-9])|(15([0-3]|[5-9]))|(16[2]|[5-7])|(17[0-3]|[5-8])|(18[0-9])|(19[1|8|9]))\\d{8}$";
        const phoneRegex = new RegExp(str)
        const onFinish = async(values: any) => {
            if(!values.code) {
                message.error('请验证手机号码');
                return;
            }
            let apply = new ApplyService();
            const res:any = await apply.createApply(values);
            console.dir(res)
            if(res.code===200) {
                message.success('申请成功 !');
                this.props.closeModel();
            }else {
                message.error('申请失败 !');
            }
            console.log('Received values of form: ', values);
        };
        return (
            <div>
                {openModel && <div>
                    <div className="model_shadow"></div>
                    <div className="apply_model">
                        <div className="apply_model__closeBtn" onClick={this.props.closeModel}><CloseOutlined /></div>
                        <div className="apply_model__title">申请试用</div>
                        <div className="apply_model__des">尊敬的用户, 我们将提供免费试用的额度,</div>
                        <div className="apply_model__des">请留下企业信息和联系方式, 我们将会在第一时间联系您。</div>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}>
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: '请输入姓名' }]}
                            >
                                <Input
                                    prefix={<UserOutlined />} placeholder="请输入姓名" />
                            </Form.Item>
                            <Form.Item
                                name="mobile"
                                rules={[{ required: true, message: '请输入手机号码' },
                                {
                                    pattern: phoneRegex,
                                    message: '请输入正确的手机号码',
                                },
                                ]}
                            >
                                <Input
                                    prefix={<PhoneOutlined />}
                                    type="tel"
                                    placeholder="请输入手机号码"
                                    maxLength={11}
                                    onBlur={this.getMobile}
                                />
                            </Form.Item>
                            <Form.Item
                                name="code"
                            >
                                <Input.Search
                                    prefix={<KeyOutlined />}
                                    // disabled={this.state.isTimeOut}
                                    enterButton= {this.state.getCodeText}
                                    placeholder="请输入验证码"
                                    onSearch={this.getCode}
                                 />
                                
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: '请输入邮箱地址' },
                                    {
                                        type: 'email',
                                        message: '请输入正确的邮箱地址',
                                    },]}
                            >
                                <Input
                                    prefix={<MailOutlined />}
                                    placeholder="请输入邮箱地址"
                                />
                            </Form.Item>
                            <Form.Item
                                name="company_name"
                                rules={[{ required: true, message: '请输入公司名称' }]}
                            >
                                <Input
                                    prefix={<HomeOutlined />}
                                    placeholder="请输入公司名称"
                                />
                            </Form.Item>
                            <Form.Item
                                name="remark"
                            >
                                <Input.TextArea
                                    placeholder="请简单描述您的需求"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    确定
                                    </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>}
            </div>
        );
    }
}

export default ApplyModel;
