import React, { Component } from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Main from "../Screens/Main/Main";
import AnalyzeResult from "../Screens/AIAnalyze/AnalyzeResult/AnalyzeResult";
import AIAnalyze from "../Screens/AIAnalyze/AIAnalyze";

interface RoutertProps {
}

interface RouterState { }

class Router extends Component<RoutertProps, RouterState> {
    public state = {
    }

    public render() {
        return (
            <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/analyze" component={AIAnalyze} />
                <Route path="/analyze_result" component={AnalyzeResult} />
                <Redirect to="/" />
            </Switch>
        );
    }
}

export default Router;