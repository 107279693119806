// 引入 axios
import axios from 'axios'

// 线上地址
// axios.defaults.baseURL = '';  
// demo地址
// axios.defaults.baseURL = '';  
// axios.defaults.timeout = 10000;  // 超时时间  10s

if(process.env.NODE_ENV === 'development'){
  console.log('development')
}else if(process.env.NODE_ENV === 'production'){
  console.log('production')
  axios.defaults.baseURL = 'https://api.ucpin.com/v1/';
}

let Http = {
  /** get 请求
   * @param  {接口地址} url
   * @param  {请求参数} params
   */
  get: function(url:string, params?:any){
    params = params || {}
    return new Promise((resolve,reject) => {
      axios.get(url,{
        params:params
      })
      .then((res) => {
        resolve( res.data);
      })
      .catch((error) => {
        console.log(error)
        reject( error );
      });
    })
  },
  /** post 请求
   * @param  {接口地址} url
   * @param  {请求参数} params
   */
  post: function(url:string, params?:any){
    params = params || {};
    return new Promise((resolve,reject) => {
      axios.post(url,params)
      .then((res) => {
        resolve( res.data );
      })
      .catch((error) => {
        console.log(error)
        reject( error );
      });
    })
  }
}

export default Http
