import React, { Component } from 'react';
import '../AnalyzeResult.scss';
import ResumeCardItemDetail from './ResumeCardItemDetail'
import ResumeCardItemP from './ResumeCardItemP'

interface props {
    info: any[],
    type: string,
}

interface state {

}

class ResumeCardExp extends Component<props, state> {
    public render() {
        const { info, type } = this.props
        return (
                <div>
                    {type === "projects" && info.map((projectsItem: any, projectsIndex) =>
                        <div key={projectsIndex + 'projectsItem'}>
                            <h3>{projectsItem.start_time + '-' + projectsItem.end_time + ' ' + projectsItem.company + ' '}</h3>
                            <div className="analyze_result__content">
                                <ResumeCardItemDetail title="职位" value={projectsItem.title} />
                                <ResumeCardItemDetail title="职责" value={projectsItem.salary} />
                                <ResumeCardItemP title="工作描述" value={projectsItem.desc} type='normal' />
                            </div>
                        </div>
                    )}
                    {type === "educations" && info.map((educationsItem: any, educationsIndex) =>
                        <div key={educationsIndex + 'educationsItem'}>
                            <h3>{educationsItem.start_time + ' ' + educationsItem.end_time + ' ' + educationsItem.school + ' '}</h3>
                            <div className="analyze_result__content">
                                <ResumeCardItemDetail title="学历" value={educationsItem.degree} />
                                <ResumeCardItemDetail title="专业" value={educationsItem.major} />
                                <ResumeCardItemP title="专业介绍" value={educationsItem.desc} type='normal' />
                            </div>
                        </div>
                    )}
                </div>

        )
    }
}

export default ResumeCardExp